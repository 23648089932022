<template>
  <div class="workAreaForm">
    <div class="workAreaForm-t">
      <div class="item">
        <div class="item-l">房号：</div>
        <div class="item-r">{{ postData.name }}</div>
      </div>
      <div class="item">
        <div class="item-l">地址：</div>
        <div class="item-r">{{ postData.path }}</div>
      </div>
      <div class="item">
        <div class="item-l">描述：</div>
        <div class="item-r">{{ postData.decoration }}</div>
      </div>
      <div class="item">
        <div class="item-l">状态：</div>
        <div :class="('item-r', { active: postData.rent == '未出租' })">
          {{ postData.rent }}
        </div>
      </div>
      <div class="item te" @click="isshow">
        <div class="item-l">变更状态：</div>
        <div class="item-r">{{ statusValue }}</div>
      </div>
    </div>
    <div class="workAreaForm-c"></div>
    <v-picker
      :columns="statusList"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <div class="workAreaForm-b" @click="submit">变更</div>
  </div>
</template>

<script>
import { updateStatusUrl, createWorkDetail } from "./api.js";
import { statusList } from "./map.js";
export default {
  name: "workAreaForm",
  data() {
    return {
      statusList,
      pickShow: false,
      valueKey: "value",
      statusValue: "",
      disabled: false,
      form: {
        userName: "",
        mobile: "",
        companyName: "",
        companyProfile: "",
      },
      spaceId: "",
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.spaceId = this.$route.query.spaceId;
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    clickPickOverlay() {
      this.pickShow = false;
    },
    cancelPick() {
      this.pickShow = false;
    },
    confirmPick(oValue) {
      console.log(oValue);
      this.pickShow = false;
      this.statusValue = oValue.value;
    },
    isshow() {
      this.pickShow = true;
    },
    submit() {
      let _this = this;
      if (!this.statusValue) {
        this.$toast({ message: "请选择变更状态", duration: 2000 });
        return;
      }
      let params = [
        {
          extra: {
            rent: _this.statusValue,
          },
          spaceId: _this.spaceId,
        },
      ];
      this.$axios
        .post(`${updateStatusUrl}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "变更成功", duration: 2000 });
            this.$router.go(-1);
          } else {
          }
        });
    },

    getDetails() {
      let params = {
        id: this.spaceId,
      };
      this.$axios
        .post(`${createWorkDetail}?spaceId=${this.spaceId}`)
        .then((res) => {
          if (res.code === 200) {
            this.postData = res.data;
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.workAreaForm {
  padding: 32px;
  .workAreaForm-t {
    .item {
      padding: 10px 0;
      display: flex;
      font-size: 30px;
      .item-l {
        width: 200px;
      }
      .item-r {
        flex: 1;
      }
      .active {
        color: #009aff;
      }
    }
    .te {
      align-items: center;
      .item-r {
        height: 42px;
        border-bottom: 1px solid #999;
        padding: 10px 0;
      }
    }
  }
  .workAreaForm-b {
    width: calc(~"100% - 64px");
    height: 80px;
    background: #009aff;
    border-radius: 8px;
    font-size: 32px;
    line-height: 80px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    color: #fff;
  }
}
</style>
<style lang="less">
.workAreaForm {
}
</style>
